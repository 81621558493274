@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Book.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Medium.woff2");
  font-weight: 500;
}
