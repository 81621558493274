@import "styles/base/variables"

#home .hero
  display: flex // Refactor can we do it with something simpler?
  flex-direction: column
  justify-content: flex-end
  height: calc(67vh - 60px)
  background-color: $color-primary
  color: white

  .title
    font-size: 44px
    line-height: 53px
    margin-bottom: 5px

  .subtitle
    @include standard-text-mobile

    text-transform: uppercase
    color: #A895A2 // special color only used here
    font-weight: lighter
    margin-bottom: 0 // To clean the margin of the last child

@media (min-width: $media-desktop)
  #home .hero
    .container
      width: 100% // to avoid flex messing up the size

    .title
      font-size: 48px
      line-height: 56px

    .subtitle
      @include standard-text-desktop