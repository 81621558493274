@import "styles/base/variables"

.item-technology
  display: flex
  flex-direction: row

  p
    margin-bottom: 0

  .circle
    $size: 40px

    flex-shrink: 0 // to avoid flex changing the size
    width: $size
    height: $size
    margin-right: $space-medium
    background-color: $color-primary
    color: $color-secondary
    text-align: center
    border-radius: $size
    line-height: $size
    font-size: $space-medium

@media (min-width: $media-desktop)
  .item-technology
    display: block

    .circle
      $size: 56px

      width: $size
      height: $size
      border-radius: $size
      line-height: $size
      margin-bottom: $space-medium
      font-size: $space-big 