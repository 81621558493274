$swatch-charcoal: #212529
$swatch-salmon: #D1A19D
$swatch-purple: #522B45
$swatch-purple-light: #5C4B5D
$swatch-gray: #6F6F6F
$swatch-blue: #003A6C
$swatch-cyan: #AFDFF9

// LAD Dashboard
$swatch-ripe-lemon: #F0CC49
$swatch-subtle-silk: #EEF0F1