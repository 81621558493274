@import "styles/base/variables"

#home .about
  .support-picture
    display: none

  .buttons
    display: flex
    gap: $space-small

@media (min-width: $media-desktop)
  #home .about
    .mobile-hero
      display: none

    .support-picture
      display: inline

    .container
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: $space-medium
