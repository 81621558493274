@import "styles/base/variables"

#course .projects
  // solution to enable horizontal scroll on mobile
  .mobile-items
    display: flex
    overflow-x: scroll

    .item-project
      flex-shrink: 0 // to avoid the flex shink
      width: 180px
      margin-right: $space-big

    // to align items to the container
    .item-project:first-child
      box-sizing: content-box // to make the padding not count into the size
      padding-left: $space-big

    .item-project:last-child
      box-sizing: content-box
      padding-right: $space-big

  .desktop-items
    display: none

@media (min-width: $media-desktop)
  #course .projects
    .mobile-items
      display: none
      
    .desktop-items
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-column-gap: $space-big
      grid-row-gap: $space-big