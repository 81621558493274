// Fix #1: For the footer being in the middle of the screen on short pages

  
@import "./variables"

*
  margin-top: 0
  box-sizing: border-box

body
  margin: 0
  position: relative // for footer


img
  max-width: 100%
  height: auto
  vertical-align: middle // to eliminate the weird 3px bug of images

.App
  @include standard-text-mobile

  display: flex // Fix #1
  flex-direction: column // Fix #1
  min-height: 100vh // Fix #1

  font-family: "Gotham", Arial, Helvetica, sans-serif
  color: $color-text
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  .page
    flex-grow: 1 // Fix #1

.container
  margin: $space-big

.component-items
  display: grid
  grid-row-gap: $space-medium
  margin-bottom: $space-big

.background-dark
  overflow: auto // to respect the container margin
  background-color: $color-tertiary
  color: white

  a
    color: $color-secondary

  .button
    background-color: $color-secondary
    color: white

  .button:hover
      background-color: darken($color-secondary, 10%)

@media (min-width: $media-desktop)
  .App
    @include standard-text-desktop

  .container
    margin: $space-ultra auto
    max-width: 960px