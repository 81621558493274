@import "styles/base/variables"

#home .candidates
  .support-picture
    display: none

@media (min-width: $media-desktop)
  #home .candidates    
    .container
      display: grid 
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: $space-medium

    .support-picture
      display: inline