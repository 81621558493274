@import "styles/base/variables"
@import "styles/pages/course/Introduction"
@import "styles/pages/course/MoreInformation"
@import "styles/pages/course/Projects"
@import "styles/pages/course/Technologies"


// Note:
// This section has a weird layout, needing containers in each section because of 2 special design elements for mobile:
// 1. The about hero, a image that takes the 100% of the screen but only appears on mobile.
// 2. The projects scrolling from left to right.
#course
  .introduction,
  .technologies,
  .projects
    // so these parents respect their children margins
    overflow: auto 

    .container
      margin-top: 0

  .introduction
    .mobile-hero
      // to help with the layout
      margin-bottom: $space-big 

@media (min-width: $media-desktop)
  #course
    .introduction .container
      // to restore the container spacing
      margin-top: $space-ultra 