#profile .skills
  background-color: white

  h2
    text-align: center

  // This is the SASS code of ItemSkillSet
  .column-with-skills
    .title .icon
      margin-right: $space-small
      
    ul
      list-style: none
      padding: 0

      li
        margin-bottom: $space-medium

@media (min-width: $media-desktop)
  #profile .skills
    .grid
      display: grid
      grid-template-columns: repeat(3, 1fr)
      column-gap: $space-medium