#profile .projects
  background-color: white
  text-align: center

  .button
    margin-right: $space-small

@media (min-width: $media-desktop)
  #profile .projects
    width: 25%
    margin: 0 auto
