@import "./_swatches"
    
// Colors
$color-primary: $swatch-purple
$color-secondary: $swatch-salmon
$color-tertiary: $swatch-purple-light
$color-gray: $swatch-gray

// Text
$color-text: $swatch-charcoal

// Spacing
$space-small: 8px
$space-medium: 16px
$space-big: 24px
$space-ultra: 48px

// Media queries
$media-phablet: 400px
$media-tablet: 550px
$media-desktop: 1000px
$media-ultra-wide: 2000px

// Other
$footer-height-mobile: 31px
$footer-height-desktop: 45px

// Mixings
@mixin standard-text-mobile()
  font-size: 15px 
  line-height: 21px

@mixin standard-text-desktop()
  font-size: 18px 
  line-height: 26px
