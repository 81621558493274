@import "styles/base/variables"

#navigation-bar
  padding: $space-small

  a
    color: white

  .container
    margin: 0 auto
    padding: 0 // overrides default container padding

  .primary
    display: flex
    justify-content: space-between
    flex-shrink: 0 // to protect the logo from distortions

    .logo
      width: auto
      height: 44px

    .button
      margin-bottom: 0

  .secondary
    display: flex
    flex-direction: column
    align-items: center
    gap: $space-medium
    padding: $space-small

    .parent-link
      color: $color-secondary

  .show
    display: flex

  .hide
    display: none

@media (min-width: $media-tablet)
  #navigation-bar .container
    display: flex

    .primary
      .logo
        margin-right: $space-big

      .button
        display: none

    .secondary
      display: flex // to override the menu button on desktop
      flex-direction: row
      flex-grow: 1

      .parent-link
        margin-left: auto // to push the last item to the right
        text-align: right
