@import "styles/base/variables"

.item-rating
  $color: $color-secondary

  .label
    text-transform: uppercase

  .box
    border: solid 2px $color
    padding: $space-small
    margin-right: $space-small

    .icon
      color: $color-primary

  .box.full
    background-color: $color



