@import "styles/base/variables"

// to make a <button> tag look like an <a> tag
.link
  @include standard-text-mobile
  
  display: inline
  padding: 0
  margin: 0
  border: none
  background-color: transparent
  outline: none
  color: $color-primary
  text-decoration: none
  cursor: pointer
  -webkit-appearance: none

  &:hover
    text-decoration: underline

@media (min-width: $media-desktop)
  .link
    @include standard-text-desktop

