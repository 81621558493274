@import "styles/base/variables"

.item-project
  white-space: pre-wrap // to recover from the no-wrap in the parent

  img
    object-fit: cover
    aspect-ratio: 16 / 9 // experimental to maintain aspect ratio
    margin-bottom: $space-small
  

@media (min-width: $media-desktop)
  .item-project img
    margin-bottom: $space-medium