#profile .hero
  background-color: $swatch-purple
  color: white

  .container
    display: flex
    flex-direction: column-reverse
    gap: $space-medium

  .item-rating
    margin-bottom: $space-small

  a
    color: $color-secondary

@media (min-width: $media-desktop)
  #profile .hero
    .container
      flex-direction: row

      .content, img
        width: 50%
