#modal
  z-index: 1

  .background
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: black
    opacity: 0.5

  .content
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background-color: white