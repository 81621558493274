@import "styles/base/variables"

#course .introduction
  .support-picture
    display: none


@media (min-width: $media-desktop)
  #course .introduction
    .mobile-hero
      display: none

    .container
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: $space-medium

    .support-picture
      display: inline